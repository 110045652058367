import { ReactNode, useMemo, useEffect, useState, useRef, useLayoutEffect } from "react";
import Link from 'next/link'; 
import { useSession, signIn, signOut } from "next-auth/react"
import { Button } from "../button/Button";
import { useRouter, redirect } from 'next/navigation';
import { default as NextImage } from 'next/image';
import Logo from '../logo/logo';
import { useConfig } from "@/src/hooks/useConfig";
type PlaygroundHeader = {
  title?: ReactNode;
  height: number;
  accentColor: string;
  // isDarkMode: boolean;
  // setIsDarkMode: (isDarkMode: boolean) => void;
};

export const PlaygroundHeader = ({
  title,
  height,
  accentColor, // Receive backgroundColor
  // isDarkMode,
  // setIsDarkMode,
}: PlaygroundHeader) => {
  const router = useRouter()
  const {config, setUserSettings} = useConfig();
  const { data: session } = useSession()
  const onClinkSignOut = async () => {
    await signOut()
  }
  useEffect(() => { // Use useEffect for redirect
    if (!session || !session.user) {
      const params = new URLSearchParams(window.location.search);  // Get URL parameters
      const pathname = window.location.pathname; // Get the current path
      const isPrivacyPage = pathname.includes('privacy') || pathname.includes('terms-of-service')|| pathname.includes('signin')
      if (!params.get('recorder') && !isPrivacyPage) {  // Check if recorder is NOT true
        router.push('/');
      }
    }
  }, [session, router]); // Add router as a dependency
  const [isDarkMode, setIsDarkMode] = useState(false); // Default to dark mode

  // useEffect(() => {
  //     const storedTheme = localStorage.getItem('theme');
  //     if (storedTheme) {
  //       setIsDarkMode(storedTheme === 'dark');
  //     }
  // }, []);

  const toggleTheme = () => {
      const newTheme = isDarkMode ? 'light' : 'dark';
      setIsDarkMode(!isDarkMode);
      localStorage.setItem('theme', newTheme);
  };
  const authContent = () => {
    if (session) {
      return (
        <>
        <div className="flex items-center gap-3 justify-end px-5"> 
            <Button accentColor={accentColor} onClick={onClinkSignOut} style={{width:80,height:30,fontSize:'small'}}>Sign out</Button>
        </div>
        
        </>

      )
    }
    return (
      <div className="flex items-center gap-3 justify-end px-5"> 
           <Button accentColor={accentColor} onClick={() => {
            router.push('/auth/signin');
           }} style={{width:80,height:30,fontSize:'small'}}>Sign in</Button> 
      </div>
    )
  }
  const bannerText = "The Agentic Backend is disabled. For demo request, please email at contact@tensoragent.io"
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [duplicates, setDuplicates] = useState<string[]>([bannerText]);

  useLayoutEffect(() => {
    const calculateDuplicates = () => {
      if (!containerRef.current) return;
      
      const container = containerRef.current;
      const firstChild = container.children[0] as HTMLDivElement | undefined;
      
      if (!firstChild) return;

      const containerWidth = container.offsetWidth;
      const textWidth = firstChild.offsetWidth;
      
      if (textWidth === 0) return;

      const numRequired = Math.ceil((containerWidth * 2) / textWidth);
      setDuplicates(Array(numRequired).fill(bannerText));
    };

    calculateDuplicates();
    window.addEventListener('resize', calculateDuplicates);
    
    return () => window.removeEventListener('resize', calculateDuplicates);
  }, [bannerText]);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container || duplicates.length <= 1) return;

    let animationId: number | null = null;
    let position = 0;

    const animate = () => {
      position -= 1;
      const firstChild = container.children[0] as HTMLDivElement | undefined;
      const firstChildWidth = firstChild?.offsetWidth || 0;
      
      if (-position >= firstChildWidth) {
        position = 0;
      }
      
      container.style.transform = `translateX(${position}px)`;
      animationId = requestAnimationFrame(animate);
    };

    animationId = requestAnimationFrame(animate);
    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId);
      }
    };
  }, [duplicates]);

  return (
    <>
    <div className={`flex gap-4 justify-between items-center shrink-0 rounded-sm w-full
        ${isDarkMode ? 'bg-gray-800 border-gray-800 text-gray-100' : 'bg-[rgb(250,230,208)] border-[rgb(247,245,242)] text-[rgb(32,33,36)]'}
      `}
      style={{
        height: (height/2) + "px",
        // backgroundColor: '#000000'
      }}
      >
      <div 
        ref={containerRef}
        className="flex items-center whitespace-nowrap"
      >
        {duplicates.map((content, index) => (
          <div key={index} className="w-full flex-shrink-0 p-4">
            <h3 className={`w-full text-${accentColor}-500 text-right text-sm font-semibold`}>
              {content}
            </h3>
          </div>
        ))}
      </div>
    </div>
    <div
      // className={`w-full flex gap-4 justify-between items-center shrink-0 border-b rounded-sm border-gray-800 text-${accentColor}-500`}
      className={`flex gap-4 justify-between items-center shrink-0 border-b rounded-sm w-full
        ${isDarkMode ? 'bg-gray-800 border-gray-800 text-gray-100' : 'bg-[rgb(250,230,208)] border-[rgb(247,245,242)] text-[rgb(32,33,36)]'}
      `}
      style={{
        height: (height) + "px",
        // backgroundColor: '#000000'
      }}
    >
      <div className="flex items-center gap-3 w-full">
        <div className="flex">
          {/* <a href="https://www.pashyatu.ai">{<img src="/vidvatta_illustration.png" alt="Vidvatta Logo" width="60" height="60" />}</a> */}
          {/* <a href="https://www.pashyatu.ai">{<img src="/logo_pashyatu.png" alt="PashyatuHire Logo" width="75" height="60" className="mx-5 mt-2" />}</a> */}
          <Link href="/">
              <Logo className="mx-5" accentColor={accentColor}/>
          </Link>
        </div>
        <div className="w-full text-center">
            <h1 className={`text-${accentColor}-500 text-xl font-semibold`}>{title}</h1>
          </div>
      {authContent()}

      </div>
      {/* <button onClick={() => {
                const userSettings = { ...config.settings };
                userSettings.theme = userSettings.theme === 'light' ? 'dark' : 'light';
                setUserSettings(userSettings);
                setIsDarkMode(!isDarkMode);

              }}>
            {isDarkMode ? 'Light' : 'Dark '}
        </button> */}

    </div>
    </>
  );
};


